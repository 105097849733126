<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <Filters ref="filters" @on-apply-click="onFiltersApplyClick" />

            <DxButton text="Пересчитать стадию иска" @click="calc" class="d-none" />

            <DxDataGrid
              id="gvWrits"
              ref="gvWrits"
              v-bind="options"
              width="100%"
              style="max-width: 100%"
              :data-source="writsData"
              :word-wrap-enabled="true"
              :column-auto-width="true"
              @toolbar-preparing="onToolbarPreparing($event)"
              @content-ready="onContentReady"
            >
              <DxHeaderFilter :visible="true" :allow-search="true" />
              <DxStateStoring :enabled="true" type="local" storage-key="gvWrits_6" />
              <DxExport fileName="Журнал_исков" :enabled="true" />
              <DxSorting mode="multiple" />
              <DxColumn
                data-field="preparationDate"
                :allow-header-filtering="false"
                sort-order="desc"
                caption="Дата составления"
                data-type="date"
                width="100"
              />
              <DxColumn data-field="streetName" caption="Улица" width="180" />
              <DxColumn data-field="houseNumber" caption="Дом" width="60" :allow-header-filtering="false" />
              <DxColumn data-field="buildingNumber" caption="Корп." width="60" :allow-header-filtering="false" />
              <DxColumn data-field="flatNumber" caption="Кв." width="60" :allow-header-filtering="false" />
              <DxColumn
                data-field="accountNumber"
                caption="ЛС"
                width="100"
                cell-template="accountNumberTemplate"
                :allow-header-filtering="false"
              />
              <DxColumn
                data-field="oldAccountNumber"
                caption="Старый ЛС"
                width="100"
                cell-template="accountNumberTemplate"
                :allow-header-filtering="false"
              />
              <DxColumn data-field="name" caption="ФИО в ЛС" width="150" :allow-header-filtering="false" :visible="false" />
              <DxColumn data-field="preparationRespondents" :allow-header-filtering="false" caption="Ответчики" />
              <DxColumn data-field="flatType" caption="Тип" width="50" :visible="false" />

              <DxColumn data-field="courtDecisionExecutionDocNo" :allow-header-filtering="false" caption="Номер дела" width="100" />
              <DxColumn
                data-field="preparationSumToCollectWithPenalties"
                caption="Сумма к взысканию"
                cell-template="preparationDebtWithPenalties"
                :allow-header-filtering="false"
              />
              <DxColumn data-field="preparationSumToCollect" :allow-header-filtering="false" caption="Долг">
                <DxFormat type="fixedPoint" :precision="2" />
              </DxColumn>
              <DxColumn data-field="preparationPenalties" :allow-header-filtering="false" caption="Пени">
                <DxFormat type="fixedPoint" :precision="2" />
              </DxColumn>
              <DxColumn data-field="preparationStateFee" :allow-header-filtering="false" caption="Г/п" width="100">
                <DxFormat type="fixedPoint" :precision="2" />
              </DxColumn>
              <DxColumn data-field="preparationPeriodInterval" :allow-header-filtering="false" caption="Период" width="80px" />
              <DxColumn data-field="writState" caption="Этап">
                <DxRequiredRule />
                <DxLookup :data-source="writStatesStore" display-expr="name" value-expr="id" />
              </DxColumn>

              <DxColumn data-field="filingDate" :allow-header-filtering="false" caption="Дата подачи" data-type="date" width="70" />
              <DxColumn data-field="courtDecisionTypeId" :allow-header-filtering="false" caption="Решение">
                <DxLookup :data-source="courtDecisionTypesStore" display-expr="name" value-expr="id" />
              </DxColumn>
              <DxColumn data-field="courtDecisionDate" :allow-header-filtering="false" caption="Дата решения" data-type="date" />
              <DxColumn data-field="filingReturnDate" data-type="date" :allow-header-filtering="false" caption="Возврат" />
              <DxColumn data-field="refilingDate" data-type="date" :allow-header-filtering="false" caption="Переподача" />
              <DxColumn data-field="courtDecisionCancelDate" data-type="date" :allow-header-filtering="false" caption="Отмена" />
              <DxColumn
                data-field="courtSumWithPenalties"
                caption="Сумма по решению"
                :allow-header-filtering="false"
                cell-template="courtSumWithPenalties"
              />
              <DxColumn data-field="courtDecisionSum" :allow-header-filtering="false" caption="Долг по решению">
                <DxFormat type="fixedPoint" :precision="2" />
              </DxColumn>
              <DxColumn data-field="courtDecisionPenalties" :allow-header-filtering="false" caption="Пени по решению" width="100">
                <DxFormat type="fixedPoint" :precision="2" />
              </DxColumn>
              <DxColumn data-field="courtDecisionStateFee" :allow-header-filtering="false" caption="Гос.пошлина по решению" width="100">
                <DxFormat type="fixedPoint" :precision="2" />
              </DxColumn>
              <DxColumn data-field="houseCourt" :allow-header-filtering="false" caption="Судебный участок" />
              <DxColumn data-field="courtDecisionIsCanceled" caption="Отмена" />
              <DxColumn data-field="preparationApplicationFormId" caption="Форма">
                <DxLookup :data-source="preparationApplicationFormsStore" display-expr="shortName" value-expr="id" />
              </DxColumn>
              <DxColumn data-field="authorUserId" caption="Автор иска" :allow-header-filtering="false">
                <DxLookup :data-source="usersStore" display-expr="name" value-expr="id" />
              </DxColumn>

              <!-- Templates -->
              <template #accountNumberTemplate="{ data }">
                <router-link :to="{ name: 'writ-card', params: { id: data.data.id, debtorId: data.data.debtorId } }">
                  {{ data.value }}
                </router-link>
              </template>
              <template #preparationDebtWithPenalties="{ data: rowInfo }">
                <div>{{ f((rowInfo.data.preparationSumToCollect ?? 0) + (rowInfo.data.preparationPenalties ?? 0)) }}</div>
              </template>
              <template #courtSumWithPenalties="{ data: rowInfo }">
                <div>{{ f((rowInfo.data.courtDecisionSum ?? 0) + (rowInfo.data.courtDecisionPenalties ?? 0)) }}</div>
              </template>

              <DxColumnChooser :enabled="true" title="Выбор столбцов" mode="select" />
              <DxFilterRow :visible="true" />
              <DxScrolling column-rendering-mode="virtual" :use-native="true" show-scrollbar="true" />
              <DxPaging :page-size="20" :enabled="true" />
              <DxPager
                :visible="true"
                :allowed-page-sizes="[5, 10, 20, 50]"
                :show-page-size-selector="true"
                :show-navigation-buttons="true"
                :show-info="true"
              />
            </DxDataGrid>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { usersStore, courtDecisionTypesStore, writStatesStore } from "@/helpers/common-store/store";
import { DxFormat, DxHeaderFilter, DxScrolling, DxColumnChooser, DxStateStoring, DxExport, DxSorting } from "devextreme-vue/data-grid";
import { debtorsFiltersComputed, authComputed } from "@/state/helpers";
import { formatNumber } from "devextreme/localization";

import createStoreExtend from "@/helpers/grid/store";
import settings from "@/helpers/grid/settings.js";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Filters from "@/components/debtors/filters.vue";
import axios from "axios";

export default {
  components: {
    Layout,
    PageHeader,
    DxFormat,
    DxHeaderFilter,
    DxScrolling,
    DxColumnChooser,
    Filters,
    DxStateStoring,
    DxExport,
    DxSorting,
  },
  computed: {
    ...debtorsFiltersComputed,
    ...authComputed,
  },
  watch: {
    currentFilters: {
      deep: true,
      handler() {
        this.onFiltersApplyClick();
      },
    },

    isFiltersEmpty: {
      immediate: false,
      handler() {
        this.toggleResetFilters();
      },
    },
  },
  mounted() {
    this.isFiltersEmpty = this.$refs.filters.isEmptyFilters();
    this.toggleResetFilters();
  },
  data() {
    return {
      usersStore,
      courtDecisionTypesStore,
      writStatesStore,
      preparationApplicationFormsStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/common/preparation-application-forms`,
      }),
      title: "Журнал исков",
      items: [
        {
          text: "Главная",
          href: "/",
        },
        {
          text: "Журнал исков",
          active: true,
        },
      ],
      isFiltersEmpty: true,
      filters: null,
      writsData: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}${process.env.VUE_APP_WRITS_GET}`,
        onBeforeSend: (_method, ajaxOptions) => {
          var self = this;
          if (this.currentFilters)
            Object.keys(this.currentFilters).forEach(function (key) {
              ajaxOptions.data[key] = self.currentFilters[key];
            });
        },
      }),
      options: settings,
    };
  },
  methods: {
    f(o) {
      return formatNumber(o, { type: "fixedPoint", precision: 2 });
    },
    async calc() {
      await axios.post(process.env.VUE_APP_URL + "/api/writs/recalculate-writs", {});
    },
    onToolbarPreparing(e) {
      let toolbarItems = e.toolbarOptions.items;
      let chooserColumn = toolbarItems.filter((r) => r.name == "columnChooserButton")[0];
      let self = this;
      chooserColumn.location = "before";
      chooserColumn.showText = "always";
      chooserColumn.options.icon = "bx bx-list-ul";
      chooserColumn.options.elementAttr = {
        class: "b secondary",
      };

      const exportBtnItem = toolbarItems.find((item) => item.name === "exportButton");
      const exportBtnIndex = toolbarItems.indexOf(exportBtnItem);
      toolbarItems[exportBtnIndex] = {
        location: "after",
        locateInMenu: "auto",
        sortIndex: 30,
        widget: "dxButton",
        visible: this.hasRightExport,
        options: {
          text: "Выгрузить в файл",
          // icon: "export-excel-button",
          hint: "Export all data",
          elementAttr: {
            class: "dx-datagrid-export-button",
          },
          onClick: function () {
            e.component.exportToExcel(false);
          },
        },
      };

      toolbarItems.push({
        widget: "dxButton",
        options: {
          // visible: false,
          // icon: "filter",
          icon: "mdi mdi-filter-off-outline",
          text: "Сбросить фильтры",
          onClick: function () {
            self.$refs["gvWrits"].instance.clearFilter();
          },
          elementAttr: {
            class: "b secondary",
          },
        },
        location: "before",
      });

      toolbarItems.push({
        widget: "dxButton",
        options: {
          visible: false,
          icon: "remove",
          elementAttr: {
            class: "b secondary",
            id: "btnResetFilters",
          },
          text: "Сбросить параметры",
          onClick: function () {
            self.resetFilters();
          },
        },
        location: "after",
      });

      toolbarItems.push({
        visible: false,
        location: "after",
        template: function () {
          return `<button class="btn btn-primary btn-label right" style="height: 27px; padding: 2px 38px 4px 10px;"
data-bs-toggle="offcanvas"  href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
  <i class="ri-arrow-right-line label-icon align-middle fs-16"></i>Поиск по параметрам
</button>`;
        },
      });
    },
    onContentReady(e) {
      var columnChooserView = e.component.getView("columnChooserView");
      if (!columnChooserView._popupContainer) {
        columnChooserView._initializePopupContainer();
        columnChooserView.render();
        columnChooserView._popupContainer.option("position", { of: e.element, my: "left top", at: "left top", offset: "0 50" });
      }
    },
    makePeriodsOpened(data) {
      return this.makePeriods(data.value);
    },
    makePeriodsClosed(data) {
      return this.makePeriods(data.value);
    },
    makePeriods(data) {
      if (!data) return "";
      return data.replaceAll(",", "<br/>\r\n");
    },
    toggleResetFilters() {
      let btn = document.getElementById("btnResetFilters");
      if (this.isFiltersEmpty) btn.classList.add("d-none");
      else btn.classList.remove("d-none");
    },
    resetFilters() {
      this.$refs.filters.resetFilters();
    },
    onFiltersApplyClick(filters) {
      this.filters = filters;
      this.isFiltersEmpty = this.$refs.filters.isEmptyFilters();
      this.$refs["gvWrits"].instance.refresh();
    },
  },
};
</script>

<style>
.dx-datagrid-headers {
  white-space: normal;
}

.offcanvas-start,
.offcanvas-end {
  width: 400px;
}

/* .dx-datagrid-export-button .dx-icon-export-excel-button::before { */
/*   content: "Экспорт в файл"; */
/* } */
</style>
